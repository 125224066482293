import React from "react";

// Components
import { H2, Text } from "@lewisblackwood/component-library";
import {
  ImageTextSection,
  TextSection,
  GridImageSection,
  CircleBackground
} from "../../layout";
import { ImageAnimated } from "../../../styledComponents/product/Image";

// images
import TaskTemplateCards from "../../../images/product/task-template-cards.svg";
import EmailTaskComposer from "../../ui/EmailTaskComposer";

const TasksFeatures = () => (
  <>
    <ImageTextSection style={{ marginTop: 40 }}>
      <TextSection>
        <H2 size="xlarge" sizeMobile="large" colour="purple">
          Create reusable personalised templates
        </H2>
        <Text size="xlarge" sizeMobile="large">
          Email templates can include placeholders like @HireName, @StartDate
          and @ManagerName.
        </Text>
        <Text size="xlarge" sizeMobile="large">
          The email will be populated with the right details for each hire and
          be sent to them at the time you set.
        </Text>
      </TextSection>
      <GridImageSection
        columns="1fr auto auto auto 1fr"
        rows="1fr auto auto auto 1fr"
      >
        <EmailTaskComposer column="3" row="3" />
        <CircleBackground colour="purple" column="2 / 5" row="2 / 5" />
      </GridImageSection>
    </ImageTextSection>

    <ImageTextSection>
      <GridImageSection
        columns="1fr auto auto auto 1fr"
        rows="1fr auto auto auto 1fr"
      >
        <ImageAnimated
          fitMobile
          src={TaskTemplateCards}
          column="3 / 3"
          row="3 / 3"
        />
        <CircleBackground colour="purple" column="2 / 5" row="2 / 5" />
      </GridImageSection>
      <TextSection>
        <H2 size="xlarge" sizeMobile="large" colour="purple">
          Set repetitive tasks to complete automatically
        </H2>
        <Text size="xlarge" sizeMobile="large">
          Specify how long before or after a hire’s start date a task should
          happen, and for which roles.
        </Text>
        <Text size="xlarge" sizeMobile="large">
          The task will complete automatically, or you will be notified to
          complete it yourself.
        </Text>
      </TextSection>
    </ImageTextSection>
  </>
);

export default TasksFeatures;
