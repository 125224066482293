import React from "react";
import {
  Curve,
  ImageTextSection,
  TextSection,
  ImageSection,
  FillMargin
} from "../../layout";
import { ImageAnimated } from "../../../styledComponents/product/Image";
import { H2, Text } from "@lewisblackwood/component-library";

// Images
import TaskTemplateApps from "../../../images/product/task-template-apps.svg";

const TasksIntro = () => (
  <>
    <Curve colour="purple" />
    <FillMargin colour="purple" />
    <ImageTextSection colour="purple">
      <ImageSection>
        <ImageAnimated fit src={TaskTemplateApps} />
      </ImageSection>
      <TextSection>
        <H2 size="xlarge" sizeMobile="large">
          Power up the tools you&nbsp;use
        </H2>
        <Text size="xlarge" sizeMobile="large" mb="xxsmall">
          Personably can automatically:
        </Text>
        <ul style={{ marginTop: 0 }}>
          <li>
            <Text size="xlarge" sizeMobile="large">
              create Google and Slack accounts
            </Text>
          </li>
          <li>
            <Text size="xlarge" sizeMobile="large">
              add hires to Google groups or other people tools
            </Text>
          </li>
          <li>
            <Text size="xlarge" sizeMobile="large">
              send emails to the new hire or internal teams
            </Text>
          </li>
        </ul>
      </TextSection>
    </ImageTextSection>
    <FillMargin colour="purple" right />
    <Curve colour="purple" flipped />
  </>
);

export default TasksIntro;
