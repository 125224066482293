import React from "react";
import { Link } from "gatsby";
import {
  GridSection,
  Curve,
  ContentSection,
  FillMargin,
  TextSection
} from "../../layout";
import { H2, Text, TextLink } from "@lewisblackwood/component-library";
import { Avatar } from "../../../styledComponents/Common";

// Images
import Ben from "../../../images/profile-ben.jpg";

const TasksQuote = () => (
  <GridSection
    column="1 / 4"
    rows="repeat(5, auto)"
    fullWidth
    columns="minmax(16px, 1fr) auto minmax(16px, 1fr)"
  >
    <Avatar image={Ben} row="2" column="2" />
    <Curve colour="purple" row="1 / 4" column="1 / 4" />
    <FillMargin colour="purple" />
    <ContentSection colour="purple" row="4">
      <TextSection quote>
        <H2 center size="large" sizeMobile="default" mb="xxsmall">
          Ben
        </H2>
        <Text
          center
          bold
          size="xlarge"
          sizeMobile="large"
          mb="large"
          colour="black"
        >
          People & Talent Director, Cytora
        </Text>
        <Text center size="xlarge" sizeMobile="large" mb="large">
          &#8220;A lot of these other systems are just a to-do list, and what
          you end up with is a big list of stuff… We wanted something that,
          rather than just telling us what we needed to do, would actually do it
          for us. That’s why we switched to Personably.&#8221;
        </Text>
        <Text center>
          <Link to="/blog/using-automation-to-build-efficient-people-processes-at-cytora">
            <TextLink colour="purple" bold size="large">
              Read Cytora’s story
            </TextLink>
          </Link>
        </Text>
      </TextSection>
    </ContentSection>
    <FillMargin colour="purple" right />
    <Curve colour="purple" flipped row="5" column="1 / 4 " />
  </GridSection>
);

export default TasksQuote;
