import React from "react";
import Layout from "../../components/layout";
import Helmet from "react-helmet";

// Components
import Hero from "../../components/product/tasks/Hero";
import TasksIntro from "../../components/product/tasks/TasksIntro";
import TasksFeatures from "../../components/product/tasks/TasksFeatures";
import TasksQuote from "../../components/product/tasks/TasksQuote";

const Tasks = () => (
  <Layout>
    <Helmet>
      <title>Tasks</title>
      <meta
        name="description"
        content="Automatically add your new hires to the tools they need, ready for their arrival. Schedule emails to send to them before they start and keep your team in the loop."
      />
    </Helmet>
    <Hero />
    <TasksIntro />
    <TasksFeatures />
    <TasksQuote />
  </Layout>
);

export default Tasks;
