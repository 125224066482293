import React from "react";
import OnVisible from "react-on-visible";

import { GridSection } from "../layout";
import { IconButton } from "@lewisblackwood/component-library";

// images
import {
  EmailComposer,
  EmailControls,
  EmailVariables,
  FormTags,
  TagRemovable,
  FormInputText,
  FormField
} from "../../styledComponents/home/ProductFeatures";

// icons
import IconBold from "../../images/icon-bold.svg";
import IconUnderline from "../../images/icon-underline.svg";
import IconItalic from "../../images/icon-italic.svg";
import IconList from "../../images/icon-list.svg";
import IconListNumbered from "../../images/icon-list-numbered.svg";
import IconLink from "../../images/icon-link.svg";

class EmailTaskComposer extends React.Component {
  state = { typeAnimationText: "" };

  TypeAnimation = (text, i) => {
    const delay = i === 0 ? 400 : 0;

    setTimeout(() => {
      if (i < text.length) {
        this.setState({
          typeAnimationText: text.substring(0, i + 1)
        });
        setTimeout(() => {
          this.TypeAnimation(text, i + 1);
        }, 200);
      }
    }, delay);
  };

  render() {
    return (
      <GridSection
        column={this.props.column}
        row={this.props.row}
        columns="40px 190px auto"
        rows="148px auto auto"
        style={{ width: "100%" }}
      >
        <EmailComposer>
          <FormTags>
            <TagRemovable>Hire Personal Email</TagRemovable>
          </FormTags>
          <FormField style={{ height: 40 }} />
          <EmailControls>
            <IconButton icon={IconBold} onClick={() => {}} />
            <IconButton icon={IconUnderline} onClick={() => {}} />
            <IconButton icon={IconItalic} onClick={() => {}} />
            <IconButton icon={IconList} onClick={() => {}} />
            <IconButton icon={IconListNumbered} onClick={() => {}} />
            <IconButton icon={IconLink} onClick={() => {}} />
          </EmailControls>
          <OnVisible onChange={() => this.TypeAnimation("Hey @", 0)}>
            <FormInputText>{this.state.typeAnimationText}</FormInputText>
          </OnVisible>
          <FormField comment />
        </EmailComposer>

        <EmailVariables animateIn="fadeAndRiseUp" delay={1600}>
          <span className="Selected">HireFirstName</span>
          <span>HireFullName</span>
          <span>ManagerFirstName</span>
          <span>ManagerFullName</span>
          <span>BuddyFirstName</span>
          <span>BuddyFullName</span>
          <span>StartDate</span>
          <span>SenderFirstName</span>
          <span>SenderFullName</span>
        </EmailVariables>
      </GridSection>
    );
  }
}

export default EmailTaskComposer;
