import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";
import media from "../Media";

//images
import IconCrossSmall from "../../images/icon-cross-small.svg";
import { FlexSection } from "../../components/layout";
import { Tag } from "@lewisblackwood/component-library";

const AnimatedImg = styled(ScrollAnimation).attrs({
  animateOnce: true
})`
  position: absolute;
`;

export const HireCardOneImg = styled(AnimatedImg)`
  top: 40px;
  margin-right: 24px;

  ${media.medium`

    > img {
      width: 280px;
    }
  `};
`;

export const HireCardTwoImg = styled(AnimatedImg)`
  top: 80px;
  margin-left: 24px;

  ${media.medium`
    top: 70px;

    > img {
      width: 280px;
    }
  `};
`;

export const HireCardIntegrationWorkable = styled(AnimatedImg)`
  padding-left: 128px;
  top: 32px;

  ${media.medium`
    top: 13px;
    padding-left: 138px;
  `};
`;

export const HireCardIntegrationGSuite = styled(AnimatedImg)`
  padding-left: 260px;
  top: 218px;

  ${media.medium`
    top: 166px;
    padding-left: 228px;
    }
  `};
`;

export const SessionCardOneImg = styled(AnimatedImg)`
  top: 0;
  right: 60px;

  ${media.medium`
    right: 80px;

    > img {
      width: 254px;
    }
  `};
`;

export const SessionCardTwoImg = styled(AnimatedImg)`
  top: 40px;
  right: 20px;

  ${media.medium`
    top: 40px;
    right: 60px;

    > img {
      width: 254px;
    }
  `};
`;

export const ScheduleCalendarImg = styled(AnimatedImg)`
  > img {
    width: 420px;
  }

  ${media.medium`
    > img {
      width: 320px;
    };
  `};
`;

const StyledTagRemovable = styled.div`
  background-color: #f7f0ff;
  border-radius: 4px;
  display: flex;
  font-size: 1em;
  margin: 2px 0 0 6px;
  padding: 2px 8px;
  position: relative;
  > label {
    font-size: 16px;
    position: relative;
    top: 1px;
  }

  > img {
    margin: 2px -6px 0 0;
  }
`;

export const TagRemovable = props => (
  <StyledTagRemovable>
    <label>{props.children}</label>
    <img src={IconCrossSmall} alt="" />
  </StyledTagRemovable>
);

export const EmailControls = styled.div`
  margin-bottom: 16px;

  > div {
    background: white;
    margin-right: ${props => props.theme.spacing.xxxsmall};
  }
`;

export const FormInputText = styled.p`
  font-size: 16px;
  height: 24px;
  left: 8px;
  position: absolute;
  top: 106px;
`;

export const FormField = styled.div`
  background: ${props => props.theme.colours.white.default};
  border: ${props => `1px solid ${props.theme.colours.grey.light}`};
  border-radius: 4px;
  height: ${props =>
    props.comment ? props.theme.spacing.xxxlarge : props.theme.spacing.large};
  margin-bottom: ${props => (props.comment ? "" : props.theme.spacing.medium)};
  position: initial;
  top: 96px;
  width: 100%;

  ${media.medium`
    left: 0;
    padding: 0 16px;
  `};
`;

export const FormTags = styled.div`
  display: flex;
  position: absolute;
  top: 3px;
`;

export const EmailComposer = styled(ScrollAnimation).attrs({
  animateOnce: true,
  animateIn: "fadeAndRiseUp"
})`
  grid-column: 1 / 4;
  grid-row: 1 / 3;
  position: relative;
  max-width: 420px;
  min-width: 264px;
  width: calc(100vw - 32px);

  ${media.medium`
    width: calc(50vw - 52px);
  `};
`;

export const EmailVariables = styled(AnimatedImg)`
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  grid-column: 2;
  grid-row: 2 / 4;
  height: 168px;
  overflow: hidden;
  position: relative;

  > span {
    display: block;
    font-size: 16px;
    height: 36px;
    margin: 6px 0;
    padding: 0 16px;
  }

  > .Selected {
    background-color: #f7f0ff;
    height: 36px;
    left: 0;
    margin-bottom: -2px;
    padding: 8px 16px;
    position: relative;
    top: -8px;
  }
`;

export const EmailComposerImg = styled(AnimatedImg)`
  ${media.medium`
    > img {
      width: 320px;
    }
  `};
`;

export const EmailVariablesImg = styled(AnimatedImg)`
  top: 208px;
  left: 68px;

  ${media.medium`
    top: 168px;
    left: 46px;

    > img {
      width: 150px;
    }
  `};
`;

const StyledTimeline = styled.div`
  background-color: ${props => props.theme.colours.grey.light};
  display: ${props => (props.mobileOnly ? "none" : "")};
  height: 80%;
  width: 2px;
  z-index: -1;

  ${media.small`
    display: ${props => (props.mobileOnly ? "block" : "none")};
    height: 20%;
  `}
`;

const StyledTimelineEnd = styled(StyledTimeline)`
  background-image: ${props =>
    props.flipped
      ? `linear-gradient(${props.theme.colours.grey.light}, ${
          props.theme.colours.white.default
        })`
      : `linear-gradient(${props.theme.colours.white.default}, ${
          props.theme.colours.grey.light
        })`};
  height: 10%;

  ${media.small`
    height: 10%;
  `}
`;

export const Timeline = props => (
  <FlexSection column={props.column} row={props.row}>
    <StyledTimelineEnd mobileOnly={props.mobileOnly} />
    <StyledTimeline mobileOnly={props.mobileOnly} />
    <StyledTimelineEnd flipped mobileOnly={props.mobileOnly} />
  </FlexSection>
);

export const Dot = styled.div`
  align-self: center;
  background-color: ${props =>
    props.colour ? props.theme.colours[props.colour].default : ""};
  border-radius: 50%;
  grid-column: 2;
  height: ${props => props.theme.spacing.large};
  justify-self: center;
  width: ${props => props.theme.spacing.large};

  ${media.small`
    display: none;
  `}
`;

export const TimelineTag = styled(Tag).attrs({
  size: "large"
})`
  background-color: ${props => props.theme.colours.purple.lightest};
  font-weight: 600;
  grid-row: ${props => props.row};
  grid-column: 1;
  margin: ${props => props.theme.spacing.xxlarge};
  text-align: center;
  padding: ${props => props.theme.spacing.xsmall};
  width: fit-content;
  justify-self: center;

  @media only screen and (max-width: 400px) {
    width: 100%;
  }
`;
