import React from "react";
import { ImageTextSection, TextSection, ImageSection } from "../../layout";

import { Image, Fade } from "../../../styledComponents/product/Image";
import { H1, H2, Text } from "@lewisblackwood/component-library";

// Images
import TaskCards from "../../../images/product/task-cards.svg";

const Hero = () => (
  <ImageTextSection>
    <TextSection hero>
      <H1
        size="small"
        sizeMobile="small"
        mb="xsmall"
        colour="grey"
        style={{ fontWeight: 400 }}
      >
        Tasks
      </H1>
      <H2 size="xxlarge" sizeMobile="large">
        Have everything set up for their first day
      </H2>
      <Text size="xlarge" sizeMobile="large">
        Automatically add your new hires to the tools they need, ready for their
        arrival.
      </Text>
      <Text size="xlarge" sizeMobile="large">
        Schedule emails to send to them before they start and keep your team in
        the loop.
      </Text>
    </TextSection>
    <ImageSection>
      <Image src={TaskCards} cropped>
        <Fade />
      </Image>
    </ImageSection>
  </ImageTextSection>
);

export default Hero;
